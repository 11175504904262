import $ from 'jquery';
import './scss/style.scss';

$('.open').click(function(){
  $('#modal').addClass('active');
})

$('.close').click(function(){
  $('#modal').removeClass('active');
})

$('.open-project').click(function(){
  $('#modal-project').addClass('active');
})

$('.close-project').click(function(){
  $('#modal-project').removeClass('active');
})

